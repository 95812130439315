// extracted by mini-css-extract-plugin
export var antBtnLg = "PageNotFound-module--ant-btn-lg--AqGcW";
export var antBtnPrimary = "PageNotFound-module--ant-btn-primary--9k0nU";
export var antFormItem = "PageNotFound-module--ant-form-item--xxEYp";
export var antFormItemLabel = "PageNotFound-module--ant-form-item-label--JHu13";
export var antInput = "PageNotFound-module--ant-input--wogRR";
export var antMessageNotice = "PageNotFound-module--ant-message-notice--S6cwW";
export var antMessageNoticeContent = "PageNotFound-module--ant-message-notice-content--WUGLP";
export var errorMessage = "PageNotFound-module--errorMessage--c7CpP";
export var img = "PageNotFound-module--img--SHcUE";
export var innerWrapper = "PageNotFound-module--innerWrapper--qa3FD";
export var text = "PageNotFound-module--text--63Cyk";
export var title = "PageNotFound-module--title--ASAmg";
export var wrapper = "PageNotFound-module--wrapper--8rD-J";