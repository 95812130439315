import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import ContentWrap from '../ContentWrap/ContentWrap';
import Button from '../Button/Button';
import { HOME_ROUTE } from '../../const/routes.const';
import pageNotFoundSrc from '../../assets/images/page_not_found.png';
import pageNotFoundSrc2x from '../../assets/images/page_not_found_2x.png';
import ImageContainer from '../ImageContainer/ImageContainer';
import { ButtonColor } from '../../const/button.conts';
import * as styles from './PageNotFound.module.scss';

const PageNotFound = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <ContentWrap>
      <div className={styles.innerWrapper}>
        <div className={styles.title}>
          <Trans>Error 404</Trans>
        </div>
        <div className={styles.text}>
          <Trans>
            Oops! Something went wrong. The page you are looking for is not
            found.
          </Trans>
        </div>
        <Button
          color={ButtonColor.white}
          label={<Trans>Home</Trans>}
          link={HOME_ROUTE}
        />
      </div>
    </ContentWrap>
    <div className={styles.img}>
      <ImageContainer
        src={pageNotFoundSrc}
        srcSet={{ _2x: pageNotFoundSrc2x }}
      />
    </div>
  </div>
);

export default PageNotFound;
