import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../layouts';
import PageNotFound from '../../components/PageNotFound/PageNotFound';
import RobotsNoIndex from '../../components/RobotsNoIndex/RobotsNoIndex';

const NotFoundPage = (): React.ReactElement => (
  <Layout>
    <PageNotFound />
  </Layout>
);

export default NotFoundPage;

export function Head() {
  return <RobotsNoIndex />;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["translation", "notFoundPage"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
